import React from "react";
import Footers from "./Footers";
import Headers from "./Headers";

const Layout = (props) => {
  return (
    <>
      <Headers />
      <div style={{ paddingTop: 65 }} />
      {props.children}
      <Footers />
    </>
  );
};

export default Layout;
