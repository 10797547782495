import React, { useEffect, useState } from "react";
import { Button, Drawer, Layout } from "antd";
import "../css/Header.css";
import Menus from "./Menus";
import Constants from "../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { setGlobal } from "../redux/actions";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { MenuOutlined } from "@ant-design/icons";
const { Header } = Layout;

const Headers = () => {
  const dispatch = useDispatch();

  const global = useSelector((state) => state.global);

  const globals = () => {
    fetch(`${Constants.URL}/global`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        dispatch(setGlobal(res));
      });
  };

  useEffect(() => {
    globals();
  }, []);

  const { md, lg } = useBreakpoint();
  const [visible, setVisible] = useState(false);
  return (
    <Header
      style={{
        backgroundColor: "#1890ff",
        position: "fixed",
        zIndex: 1,
        width: "100%",
        borderBottom: "1px solid #ccc",
      }}
    >
      <div className="logo">
        {global.navbar ? (
          <img
            style={{ width: "100%" }}
            src={`${Constants.URL}${global.navbar.logo.url}`}
          />
        ) : null}
      </div>
      {md ? (
        <Menus />
      ) : (
        <>
          <Button
            size="large"
            style={{ color: "yellow" }}
            type="text"
            icon={<MenuOutlined />}
            onClick={() => setVisible(!visible)}
          ></Button>
          <Drawer
            placement="left"
            onClose={() => setVisible(false)}
            visible={visible}
          >
            <Menus />
          </Drawer>
        </>
      )}
    </Header>
  );
};

export default Headers;
