import { DatePicker } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router";
import Botones from "../components/Botones";
import Galeria from "../components/Galeria";
import Layout from "../components/Layout";
import Precios from "../components/Precios";
import SectionsColumgroup from "../components/SectionsColumgroup";
import SectionsHero from "../components/SectionsHero";
import SectionsRowgroup from "../components/SectionsRowgroup";
import Slider from "../components/Slider";
import Textoenriquesido from "../components/Textoenriquesido";
import Constants from "../utils/Constants";
const Home = (props) => {
  const [data, setData] = useState({ contentSections: [] });
  const datahome = () => {
    fetch(`${Constants.URL}/pages/9`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setData(res);
      });
  };

  useEffect(() => {
    datahome();
  }, []);

  const sections = data.contentSections;
  console.log(sections);
  return (
    <Layout>
      <Content>
        {sections.map((sec) => {
          console.log(sec.__component);
          if (sec.__component == "sections.hero") {
            return <SectionsHero contenido={sec} />;
          } else if (sec.__component == "sections.feature-rows-group") {
            return <SectionsRowgroup contenido={sec} />;
          } else if (sec.__component == "sections.feature-columns-group") {
            return <SectionsColumgroup contenido={sec} />;
          } else if (sec.__component == "sections.slider") {
            return <Slider contenido={sec} />;
          } else if (sec.__component == "sections.rich-text") {
            return <Textoenriquesido contenido={sec} />;
          } else if (sec.__component == "sections.bottom-actions") {
            return <Botones contenido={sec} />;
          } else if (sec.__component == "sections.galeria") {
            return <Galeria contenido={sec} />;
          } else if (sec.__component == "sections.pricing") {
            return <Precios contenido={sec} />;
          }
        })}
      </Content>
    </Layout>
  );
};

export default Home;
