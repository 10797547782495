import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Constants from "../utils/Constants";
import { Content } from "antd/lib/layout/layout";
const SectionsColumgroup = ({ contenido }) => {
  const n = 24 / contenido.features.length;
  return (
    <Content
      style={{
        padding: "50px 50px",
        marginTop: 40,
        backgroundColor: "#1890ff",
      }}
    >
      <Row>
        {contenido.features.map((contenido, index) => {
          return (
            <Col style={{ textAlign: "center" }} md={24} lg={Math.floor(n)}>
              <img
                style={{ width: "50%", maxWidth: "200px" }}
                src={Constants.URL + contenido.icon.url}
              />
              <h4>{contenido.label}</h4>
              <h3>{contenido.title}</h3>
              <p>{contenido.description}</p>
            </Col>
          );
        })}
      </Row>
    </Content>
  );
};

export default SectionsColumgroup;
