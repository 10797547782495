export const setUser = (user) => {
  return {
    type: "SET_USER",
    user: user,
  };
};

export const setDiccionario = (diccionario) => {
  return {
    type: "SET_DICCIONARIO",
    diccionario: diccionario,
  };
};

export const setGlobal = (global) => {
  return {
    type: "SET_GLOBAL",
    global: global,
  };
};
