import React from "react";
import { Col, Layout, Row, List, Typography } from "antd";
import { useSelector } from "react-redux";
import Constants from "../utils/Constants";
import { Link } from "react-router-dom";

const { Footer } = Layout;

const Footers = (props) => {
  const global = useSelector((state) => state.global);

  const footer = global.footer;
  return (
    <Footer style={{ backgroundColor: "#1890ff" }}>
      <Row>
        <Col
          lg={12}
          md={24}
          style={{ alignContent: "center", alignItems: "center" }}
        >
          {global.footer ? (
            <img
              style={{ textAlign: "center", width: "40%", marginTop: 40 }}
              src={`${Constants.URL}${footer.logo.url}`}
            />
          ) : null}
        </Col>
        <Col lg={12} md={24} style={{ alignItems: "end", alignContent: "end" }}>
          {global.footer ? (
            <Row
              style={{
                alignItems: "end",
                alignContent: "end",
                flexDirection: "row",
              }}
            >
              {footer.columns.map((col) => {
                return (
                  <Col lg={6} md={12}>
                    <h2 style={{ marginLeft: 20 }}>{col.title}</h2>
                    <List
                      style={{ border: "none" }}
                      size="large"
                      bordered
                      dataSource={col.links}
                      renderItem={(item) => (
                        <List.Item style={{ border: "none" }}>
                          <a href={item.url}>{item.text}</a>
                        </List.Item>
                      )}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : null}
        </Col>
      </Row>
    </Footer>
  );
};

export default Footers;
