import { Button, Carousel, Col, Row } from "antd";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import React from "react";
import { Link } from "react-router-dom";
import Constants from "../utils/Constants";

const Slider = ({ contenido }) => {
  const slider = contenido.slider;
  console.log(slider);
  const { md } = useBreakpoint();
  return (
    <div>
      <Carousel autoplay>
        {slider.map((slide) => {
          return (
            <div>
              <h3
                style={{
                  backgroundImage: `url(${Constants.URL}${slide.url})`,
                  height: md ? "600px" : "250px",
                  color: "#fff",
                  textAlign: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></h3>
            </div>
          );
        })}
      </Carousel>
    </div>
  );
};

export default Slider;
