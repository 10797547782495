import { Button, Col, Image, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Constants from "../utils/Constants";
import { Content } from "antd/lib/layout/layout";
const Galeria = ({ contenido }) => {
  const n = 24 / contenido.galeria.length;
  return (
    <Content style={{ padding: "0 50px" }}>
      <h1 style={{ textAlign: "center" }}>{contenido.title}</h1>
      <Row gutter={16}>
        {contenido.galeria.map((contenido, index) => {
          return (
            <Col
              style={{
                textAlign: "left",
                padding: 10,
              }}
              md={24}
              lg={Math.floor(n) >= 8 ? Math.floor(n) : 8}
            >
              <div>
                <Image src={`${Constants.URL}${contenido.url}`} />
              </div>
            </Col>
          );
        })}
      </Row>
    </Content>
  );
};

export default Galeria;
