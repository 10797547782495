import { createStore, combineReducers, compose } from "redux";
import reduxLocal from "redux-localstorage";

function user(state = { nombre: "juan perez" }, action) {
  switch (action.type) {
    case "SET_USER":
      return action.user;
    case "CLEAR_USER":
      return {};
    default:
      return state;
  }
}

function diccionario(state = {}, action) {
  switch (action.type) {
    case "SET_DICCIONARIO":
      return action.diccionario;
    case "CLEAR_DICCIONARIO":
      return {};
    default:
      return state;
  }
}

function global(state = {}, action) {
  switch (action.type) {
    case "SET_GLOBAL":
      return action.global;
    case "CLEAR_GLOBAL":
      return {};
    default:
      return state;
  }
}

let rootReducer = combineReducers({
  user: user,
  diccionario: diccionario,
  global: global,
});

let mainEnhancer = compose(reduxLocal());

export default createStore(rootReducer, {}, mainEnhancer);
