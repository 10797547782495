import { Button, Col, Row } from "antd";
import React from "react";
import Constants from "../utils/Constants";
import { Content } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
const SectionsHero = ({ contenido }) => {
  console.log(contenido);
  return (
    <Content style={{ padding: "0 50px", marginTop: 40 }}>
      <Row>
        <Col lg={13} md={24} style={{ alignContent: "end" }}>
          <img
            style={{ float: "left", width: "90%", textAlign: "right" }}
            src={Constants.URL + contenido.picture.url}
          />
        </Col>
        <Col md={24} lg={11}>
          <h2>{contenido.label}</h2>
          <h1 style={{ fontSize: 45 }}>{contenido.title}</h1>
          <p>{contenido.description}</p>
          {contenido.buttons.length > 0
            ? contenido.buttons.map((boton) => {
                return (
                  <Button
                    size="large"
                    style={{ marginRight: 10 }}
                    type={boton.type}
                  >
                    <Link to={boton.url}> {boton.text}</Link>
                  </Button>
                );
              })
            : null}
        </Col>
      </Row>
    </Content>
  );
};

export default SectionsHero;
