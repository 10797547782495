import { Button, Col, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Constants from "../utils/Constants";
import { Content } from "antd/lib/layout/layout";
const Precios = ({ contenido }) => {
  const n = 24 / contenido.plans.length;
  return (
    <Content style={{ padding: "0 50px" }}>
      <h1 style={{ textAlign: "center" }}>{contenido.title}</h1>
      <Row gutter={16}>
        {contenido.plans.map((contenido, index) => {
          return (
            <Col
              style={{
                textAlign: "left",
                padding: 20,
              }}
              md={24}
              lg={Math.floor(n) >= 8 ? Math.floor(n) : 8}
            >
              <div
                style={{
                  backgroundColor: contenido.isRecommended
                    ? "#49e249"
                    : "#1890ff",

                  padding: 30,
                  borderRadius: 10,
                }}
              >
                <h2>{contenido.name}</h2>
                <h3>{contenido.title}</h3>
                <p>{contenido.description}</p>
                <p>${contenido.price}</p>
                <p>Duracion: {contenido.pricePeriod}</p>
              </div>
            </Col>
          );
        })}
      </Row>
    </Content>
  );
};

export default Precios;
