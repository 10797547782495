import React from "react";
import { Menu } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";

const Menus = (props) => {
  const global = useSelector((state) => state.global);

  const { md } = useBreakpoint();
  return (
    <Menu
      mode={md ? "horizontal" : "inline"}
      style={{
        backgroundColor: !md ? "white" : "#1890ff",
        alignContent: "flex-end",
        alignItems: "flex-end",
      }}
    >
      {global.navbar
        ? global.navbar.links.map((link) => {
            return (
              <Menu.Item key={link.id}>
                <Link to={link.url}>{link.text}</Link>
              </Menu.Item>
            );
          })
        : null}
    </Menu>
  );
};

export default Menus;
