import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Page from "./containers/Page";

const AppRoutes = () => (
  <Switch>
    <Route path="/page/:url" component={Page} />
    <Route path="/" exact={true} component={Home} />
  </Switch>
);

export default AppRoutes;
