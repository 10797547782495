import { Button, Carousel, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { Link } from "react-router-dom";
import Constants from "../utils/Constants";
import "../index.less";
const Textoenriquesido = ({ contenido }) => {
  return (
    <Content style={{ padding: "0 50px" }}>
      {ReactHtmlParser(contenido.content)}
    </Content>
  );
};

export default Textoenriquesido;
