import { Button, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { Link } from "react-router-dom";
import Constants from "../utils/Constants";

const SectionsRowgroup = ({ contenido }) => {
  return (
    <Content style={{ padding: "0 50px", marginTop: 40 }}>
      {contenido.features.map((contenido, index) => {
        return (
          <Row
            style={{
              flexDirection: index % 2 === 0 ? "row" : "row-reverse",
              marginTop: 40,
            }}
          >
            <Col style={contenido} lg={12} md={24}>
              <h4>{contenido.label}</h4>
              <h1 style={{ fontSize: 45 }}>{contenido.title}</h1>
              <p>{contenido.description}</p>
              {contenido.link !== null ? (
                <Link to={contenido.link.url}>{contenido.link.text}</Link>
              ) : null}
            </Col>
            <Col md={24} lg={12}>
              <img
                style={{ float: "right", width: "100%" }}
                src={Constants.URL + contenido.media.url}
              />
            </Col>
          </Row>
        );
      })}
    </Content>
  );
};

export default SectionsRowgroup;
