import { Button, Carousel, Col, Row } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import Constants from "../utils/Constants";

const Botones = ({ contenido }) => {
  return (
    <Content
      style={{
        padding: "0 0",
        alignContent: "center",
        alignItems: "center",
        textAlign: "center",

        padding: 60,
      }}
    >
      <h1>{contenido.title}</h1>
      {contenido.buttons.length > 0
        ? contenido.buttons.map((boton) => {
            return (
              <Link to={boton.url}>
                <Button
                  size="large"
                  style={{ marginRight: 10 }}
                  type={boton.type}
                >
                  {boton.text}
                </Button>
              </Link>
            );
          })
        : null}
    </Content>
  );
};

export default Botones;
